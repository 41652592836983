import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UITKInputModule } from '@uitk/angular';
import { AppService } from './services/app.service';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    UITKInputModule
  ],
  providers: [
    AppService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppService],
      useFactory: (appService: AppService) => {
        return () => {
          return appService.getAppConfigSettings();
        };
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
