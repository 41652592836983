import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { MemberEligibilityService } from './services/member-eligibility.service';
import { MemberEnrollmentService } from './services/member-enrollment.service';
import { AppService } from './services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'self-enroll-auth';
  public isEnrollment:boolean=false;
  public firstName:string;
  public lastName: string;
  public dateOfBirth: string;
  public memberId: string;
  public isDateInvalid: boolean = false;
  public submitted: boolean = false;

  public addressLine1: string;
  public addressLine2: string;
  public city: string;
  public state: string;
  public zip: string;
  public altAddressLine1: string;
  public altAddressLine2: string;
  public altCity: string;
  public altState: string;
  public dropdownSate: any[];
  public altZip: string;
  public mobile: string;
  public mobileConfirmed: string;
  public isMobileConfirmed:boolean=false;  
  public email: string;
  public emailConfirmed: string;
  public isEmailConfirmed: boolean = false;
  public isConfirmEmailValueHide: boolean = true;
  public checkboxHome:any;
  public checkboxAlt: any;
  public mobilePhoneCheck: any;
  public isMobilePhoneCheck: boolean = true;
  public isAddressChcecked: boolean = true;
  public isHomeAddressConf: boolean = true;

  public isEligibilitySuccess: boolean = true;
  public isEnrollmentSuccess: boolean = true;
  public eligibilityRespMsg: string;
  public memberIndvId: string;
  public populationGroup: string;
  public auditId: string;


  static _config: any;

  constructor(private appService: AppService, private memberEligibilityService: MemberEligibilityService, private memberEnrollmentService: MemberEnrollmentService, private spinner: NgxSpinnerService) {
    AppComponent._config = appService.appConfig;
  }

  ngOnInit() {
    this.addressLine1="Address line 1";
    this.addressLine2="Address line 2";
    this.city="City";
    this.state="State";
    this.zip = "ZIP";
    this.altState = "State";
    this.dropdownSate = AppComponent._config === null ? 'State': AppComponent._config.myAddress.dropdowns.states;
  }

  onNext() {
    this.spinner.show();
    this.dateOfBirth = (document.querySelector('#exampleInputDateOfBirth') as HTMLInputElement).value;
    this.memberEligibilityService.getEligibility(this.firstName, this.lastName, this.dateOfBirth, this.memberId).subscribe(response => {
      if (response) {
        if (response.isSuccess === true) {
          this.spinner.hide();
          this.isEnrollment = true;
          this.isEligibilitySuccess = response.isSuccess;
          this.eligibilityRespMsg = response.message;
          this.addressLine1 = response.addressLineOne;
          this.addressLine2 = response.addressLineTwo;
          this.city = response.city;
          this.state = response.state;
          this.zip = response.zip;
          this.memberIndvId = response.indvId;
          this.populationGroup = response.populationGroup;
          this.auditId = response.auditId;
          if (this.addressLine1 === null || this.city === null || this.state === null || this.zip === null)
            this.isHomeAddressConf = false;
        }
        else {
          this.spinner.hide();
          this.isEnrollment = false;
          this.isEligibilitySuccess = response.isSuccess; 
          this.eligibilityRespMsg = response.message ?? `We’re sorry for the inconvenience, we are unable to process your request online at this time. Please try again later, or call 1-855-248-1472, TTY 711, 8 a.m. – 5 p.m. CT, M-F to sign-up by phone.`;
        }
      }
      else {
        this.spinner.hide();
        this.isEnrollment = false;
        this.isEligibilitySuccess = false;
        this.eligibilityRespMsg = `We’re sorry for the inconvenience, we are unable to process your request online at this time. Please try again later, or call 1-855-248-1472, TTY 711, 8 a.m. – 5 p.m. CT, M-F to sign-up by phone.`;
      }
    },error => {
        this.spinner.hide();
        this.isEnrollment = false;
        this.isEligibilitySuccess = false;
        this.eligibilityRespMsg = `We’re sorry for the inconvenience, we are unable to process your request online at this time. Please try again later, or call 1-855-248-1472, TTY 711, 8 a.m. – 5 p.m. CT, M-F to sign-up by phone.`;
    });
  }
  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    this.memberEnrollmentService.createMemberProfile(
      this.firstName,
      this.lastName,
      this.dateOfBirth,
      this.memberId,
      this.checkboxAlt ? this.altAddressLine1 : this.addressLine1,
      this.checkboxAlt ? this.altAddressLine2 : this.addressLine2,
      this.checkboxAlt ? this.altCity : this.city,
      this.checkboxAlt ? this.altZip : this.zip,
      this.checkboxAlt ? this.altState : this.state,
      this.mobile,
      this.email,
      this.memberIndvId,
      this.populationGroup,
      this.auditId
    ).subscribe(response => {

      this.spinner.hide();
      this.isEnrollmentSuccess = false;
      this.eligibilityRespMsg = response.message;

    });
  }

  onKeyDownInDateOfBirthField(e) {
    var dob = (document.querySelector('#exampleInputDateOfBirth') as HTMLInputElement);    
    if (e.key === "Backspace" || e.key === "Delete") return;
    if (e.target.value.length === 2) {
        dob.value = dob.value + "/";
    }
    if (e.target.value.length === 5)
        dob.value = dob.value + "/";

    if (
        (e.keyCode >= 35 && e.keyCode <= 40) ||
        (e.keyCode === 65 && e.ctrlKey === true) ||
        (e.keyCode === 67 && e.ctrlKey === true) ||
        (e.keyCode === 88 && e.ctrlKey === true) ||
        (e.keyCode === 65 && e.metaKey === true) ||
        (e.keyCode === 67 && e.metaKey === true) ||
        (e.keyCode === 88 && e.metaKey === true) ||
        (e.keyCode == 9) ||
        [46, 8].indexOf(e.keyCode) !== -1
    ) {
    }
    else if (((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) ||
        ((document.querySelector('#exampleInputDateOfBirth') as HTMLInputElement).value.length > 9)) {
        e.preventDefault();
    }
}
onChangeInDateOfBirthField(e) {
  if (e.target.value === "Invalid date") {
    this.isDateInvalid = true;
    e.target.value = "";
  }
  else {
    this.isDateInvalid = false;
  }
}
onChangeInDateOfBirthValueField(e){ 
  if (e !== "Invalid date") {
    this.isDateInvalid = false;
  }
}
onKeyDownMemberId(e) {
  var planId = (document.querySelector('#exampleInputMemberId') as HTMLInputElement);
  if (e.key === "Backspace" || e.key === "Delete") return;
  if (e.target.value.length === 9)
     planId.value = planId.value + "-";

  if (
      (e.keyCode >= 35 && e.keyCode <= 40) ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode === 67 && e.ctrlKey === true) ||
      (e.keyCode === 88 && e.ctrlKey === true) ||
      (e.keyCode === 65 && e.metaKey === true) ||
      (e.keyCode === 67 && e.metaKey === true) ||
      (e.keyCode === 88 && e.metaKey === true) ||
      (e.keyCode == 9) ||
      [46, 8].indexOf(e.keyCode) !== -1
  ) {
  }
  else if (((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) ||
      ((document.querySelector('#exampleInputMemberId') as HTMLInputElement).value.length > 11)) {
      e.preventDefault();
  }
}
onKeyDownMobileNumber(e){
  var planId = (document.querySelector('#exampleInputMobile') as HTMLInputElement);
  if (e.key === "Backspace" || e.key === "Delete") return;
  if (e.target.value.length === 3)
     planId.value = planId.value + "-";
  else if (e.target.value.length === 7)
     planId.value = planId.value + "-";

  if (
      (e.keyCode >= 35 && e.keyCode <= 40) ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode === 67 && e.ctrlKey === true) ||
      (e.keyCode === 88 && e.ctrlKey === true) ||
      (e.keyCode === 65 && e.metaKey === true) ||
      (e.keyCode === 67 && e.metaKey === true) ||
      (e.keyCode === 88 && e.metaKey === true) ||
      (e.keyCode == 9) ||
      [46, 8].indexOf(e.keyCode) !== -1
  ) {
  }
  else if (((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) ||
      ((document.querySelector('#exampleInputMobile') as HTMLInputElement).value.length > 11)) {
      e.preventDefault();
  }

}

onKeyDownConfirmMobileNumber(e){
  var planId = (document.querySelector('#exampleInputConfirmMobile') as HTMLInputElement);
  if (e.key === "Backspace" || e.key === "Delete") {
    return;
  };
  if (e.target.value.length === 3)
     planId.value = planId.value + "-";
  else if (e.target.value.length === 7)
     planId.value = planId.value + "-";

  if (
      (e.keyCode >= 35 && e.keyCode <= 40) ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode === 67 && e.ctrlKey === true) ||
      (e.keyCode === 88 && e.ctrlKey === true) ||
      (e.keyCode === 65 && e.metaKey === true) ||
      (e.keyCode === 67 && e.metaKey === true) ||
      (e.keyCode === 88 && e.metaKey === true) ||
      (e.keyCode == 9) ||
      [46, 8].indexOf(e.keyCode) !== -1
  ) {
  }
  else if (((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) ||
      ((document.querySelector('#exampleInputConfirmMobile') as HTMLInputElement).value.length > 11)) {
      e.preventDefault();
  }
  

}
  onKeyUpConfirmMobileNumber(e) {
    if ((document.querySelector('#exampleInputConfirmMobile') as HTMLInputElement).value === (document.querySelector('#exampleInputMobile') as HTMLInputElement).value) {
      this.isMobileConfirmed = false;
    }
    else {
      this.isMobileConfirmed = true;
    }
  }
  onKeyDownEmailValue(e) {
    var emailVal = (document.querySelector('#exampleInputEmail') as HTMLInputElement);
    if (e.key === "Backspace" || e.key === "Delete") {
      if (emailVal.value.length < 5) {
        this.isConfirmEmailValueHide = true;
      }
      return;
    };
    if (emailVal.value.length>1) {
      this.isConfirmEmailValueHide = false;
    }
  }
  onKeyUpConfirmEmail(e) {
    if ((document.querySelector('#exampleInputEmail') as HTMLInputElement).value === (document.querySelector('#exampleInputConfirmEmail') as HTMLInputElement).value) {
      this.isEmailConfirmed = false;
    }
    else {
      this.isEmailConfirmed = true;
    }
  }
  onKeyDownZipCode(e) {
    if (e.key === "Backspace" || e.key === "Delete") return;
    if (
      (e.keyCode >= 35 && e.keyCode <= 40) ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode === 67 && e.ctrlKey === true) ||
      (e.keyCode === 88 && e.ctrlKey === true) ||
      (e.keyCode === 65 && e.metaKey === true) ||
      (e.keyCode === 67 && e.metaKey === true) ||
      (e.keyCode === 88 && e.metaKey === true) ||
      (e.keyCode == 9) ||
      [46, 8].indexOf(e.keyCode) !== -1
    ) {
    }
    else if (((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) ||
      ((document.querySelector('#exampleInputAltZIP') as HTMLInputElement).value.length > 4)) {
      e.preventDefault();
    }
  }
onHomeAddressCheckbox(e){
  this.checkboxAlt = false;
  this.isAddressChcecked = false;
}
onAltAddressCheckbox(e){
  this.checkboxHome = false;
  this.isAddressChcecked = false;
}
onMobilePhoneCheckbox(e){
  this.isMobilePhoneCheck = false;
}
onEmailCheckbox(e){
}
  onDropdownChangeLob(state:string) {
    this.altState = state;
  }
}
