import { Injectable } from '@angular/core';
import { MemberResponseModel } from '../MemberResponseModel';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MemberEligibilityService {

  constructor(private httpClient: HttpClient) { }
  getEligibility(firstName: string, lastName: string, dob: string, memberId: string) {
    let dobformat = dob.split('/');
    return this.httpClient.post<MemberResponseModel>('/nc-eligibility',
      {
        "firstName": firstName,
        "lastName": lastName,
        "dob": dobformat[2] + '-' + dobformat[0] + '-' + dobformat[1],
        "memberId": memberId
      });
  }
}
