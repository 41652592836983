import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class AppService {

  appConfig: any = null;
  constructor(private httpClient: HttpClient) { }
  getAppConfigSettings(): Promise<any>{
    const promise = this.httpClient.get("dist/assets/settings.json").toPromise().then(resp => {
      this.appConfig = resp;
    });
    return promise;
  }
}
