<div class="navbar-brand">
  <a href=".">
    <img src="../dist/assets/UHC_Wordmark_Stacked_blu_RGB.png" alt="uhc logo" class="UHClogo" />
  </a>
</div>

<div *ngIf="!isEnrollment">
  <div class="nav-header">
    <p class="nav-header-text">Managing your health is easier when you have the right help.</p>
  </div>

  <div class="form-container" style="position: relative;width: 90%; margin: 0 4rem; padding-top: 5px;">
    <div class="container" style="margin: 0;padding: 0;" *ngIf="isEligibilitySuccess">
      <div style="font-family: 'UHCSans', Helvetica, Arial, sans-serif; color: #003C71;padding-top: 5px;">
        <p style="margin-bottom: 0.1rem">
          <span>
            To get started in the care management program, please provide the
            following
            information:
          </span>
        </p>
      </div>
      <div style="font-family: 'UHCSans', Helvetica, Arial, sans-serif; color: #003C71;padding: 4px 0;">
        Fields marked with an asterisk <span class="required-ast ast-margin">*</span> are required.
      </div>

      <div class="form-content-body">
        <form class="section" (ngSubmit)="onNext()" #enrollForm="ngForm" autocomplete="off">
          <div style="font-family: 'UHCSans', Helvetica, Arial, sans-serif; color: #003C71;">
            <div class="form-row form-row-fields">
              <div class="form-group col-sm-4">
                <span for="exampleInputFirstName" class="form-label-full-width">First Name </span><span class="required-ast ast-margin">*</span>
                <input type="text" class="form-control form-input-lg" tabindex="0" id="exampleInputFirstName" required
                       name="firstName" [(ngModel)]="firstName" #firstNameValue="ngModel">
                <div *ngIf="firstNameValue.invalid && (firstNameValue.dirty || firstNameValue.touched)"
                     class="app-alert">
                  <div *ngIf="firstNameValue.errors.required">
                    First Name is required.
                  </div>
                </div>
              </div>
              <div class="form-group col-sm-4">
                <span for="exampleInputLastName" class="form-label-full-width">Middle Initial </span><span class="optional">(optional)</span>
                <input type="text" class="form-control" id="exampleInputMiddleInitial" name="MiddleInitial">
              </div>
              <div class="form-group col-sm-4">
                <span for="exampleInputLastName" class="form-label-full-width">Last Name </span><span class="required-ast ast-margin">*</span>
                <input type="text" class="form-control" id="exampleInputLastName" required name="lastName"
                       [(ngModel)]="lastName" #lastNameValue="ngModel">
                <div *ngIf="lastNameValue.invalid && (lastNameValue.dirty || lastNameValue.touched)" class="app-alert">
                  <div *ngIf="lastNameValue.errors.required">
                    Last Name is required.
                  </div>
                </div>
              </div>

            </div>
            <div class="form-row form-row-fields">
              <div class="form-group col-sm-4">
                <span for="exampleInputDateOfBirth" class="form-label-full-width">Date of birth </span><span class="required-ast ast-margin">*</span>
                <input type="text" class="form-control"
                       [bsConfig]="{isAnimated:true, containerClass: 'theme-dark-blue'}" bsDatepicker
                       id="exampleInputDateOfBirth" (change)="onChangeInDateOfBirthField($event)"
                       (bsValueChange)="onChangeInDateOfBirthValueField($event)"
                       (keydown)="onKeyDownInDateOfBirthField($event)" required name="dateOfBirth" [(ngModel)]="dateOfBirth"
                       #dateOfBirthValue="ngModel">
                <!-- <span class="hint-text">MM/DD/YYYY</span> -->
                <div *ngIf="dateOfBirthValue.invalid && (dateOfBirthValue.dirty || dateOfBirthValue.touched)"
                     class="app-alert">
                  <div *ngIf="dateOfBirthValue.errors.required">
                    Date of birth is required.
                  </div>
                  <div *ngIf="isDateInvalid">
                    Invalid date of birth.
                  </div>
                </div>
              </div>
              <div class="form-group col-sm-4">
                <span class="form-label-full-width">Member ID </span><span class="required-ast ast-margin">*</span>
                <input type="text" class="form-control" name="memberId" required [(ngModel)]="memberId"
                       #memberIdValue="ngModel" id="exampleInputMemberId" pattern="[0-9]{9}-[0-9]{2}"
                       (keydown)="onKeyDownMemberId($event)">

                <!-- <span class="hint-text">#########-##</span> -->
                <div *ngIf="memberIdValue.invalid && (memberIdValue.dirty || memberIdValue.touched)" class="app-alert">
                  <div *ngIf="memberIdValue.errors.required">
                    Member ID is required.
                  </div>
                  <div *ngIf="memberIdValue.errors?.pattern">
                    Invalid member ID.
                  </div>
                </div>
              </div>

            </div>
            <div class="form-row membercarrd-visible">
              <div class="form-group col-sm-4">
                <span class="hint-text">MM/DD/YYYY</span>
              </div>
              <div class="form-group col-sm-4">
                <span class="hint-text">#########-##</span><br />
                <span class="hint-text">(Find your 11 digit Member ID here)</span>
                <div class="membercard-boder-1"></div>
                <div class="membercard-boder-2"></div>
                <div class="membercard-boder-3"></div>
                <div class="membercard-boder-4"></div>
                <div style="position: relative;">
                  <img src="../dist/assets/Card sample.png" style="width: 100%;" />
                </div>
              </div>

            </div>
            <div class="button-next">
              <button type="submit" class="btn btn-lg btn-primary next-button"
                      [disabled]="!enrollForm.form.valid">
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="!isEligibilitySuccess">
        <div>
          <h4 class="error-header" id="responsetext" style="font-family:'UHC Sans';font-weight:500;color:#003C71;padding: 20px 15% 0 0;text-align:center">
            {{eligibilityRespMsg}}
          </h4>
          <!--<a href="javascript:void(0)" id="retryForm" (click)="onResubmit()" [hidden]="reenroll">Click here to try again</a>-->
        </div>
    </div>
  </div>

  

</div>



<div *ngIf="isEnrollment && isEligibilitySuccess">
  <div style="height: 20px;background: linear-gradient(125deg, #003C71 95%, transparent 50%) left no-repeat, linear-gradient(20deg, transparent,transparent) 35px 0 no-repeat;">
  </div>
  <div class="form-container" style="position: relative;width: 90%; margin: 0 4rem; padding-top: 5px;" *ngIf="isEnrollmentSuccess">
    <div class="container" *ngIf="true" style="margin: 0;padding: 0;">
      <div style="font-family: 'UHCSans', Helvetica, Arial, sans-serif; color: #003C71;    padding: 10px 0;font-weight: 600;">
        <p style="margin-bottom: 0.1rem">
          <span>
            You may receive health tracking tools as part of this care management
            program. Please indicate where you would like them to be shipped.
          </span>
        </p>
      </div>
      <div class="form-content-body">
        <form class="section" (ngSubmit)="onSubmit()" #submitForm="ngForm" autocomplete="off">
          <div style="font-family: 'UHCSans', Helvetica, Arial, sans-serif; color: #003C71;">
            <div style="height:25px">
              <div class="app-alert" style="font-family:'UHCSans', Helvetica, Arial, sans-serif;" [hidden]="checkboxHome || checkboxAlt || isAddressChcecked">
                Please check the appropriate address box below.
              </div>
            </div>
            <div class="form-row" *ngIf="isHomeAddressConf">
              <div class="form-group col-sm-4">
                <div class="d-flex">
                  <div class="checkbox-container">
                    <input type="checkbox" id="addressListHome" name="addressListHome" [(ngModel)]="checkboxHome" #checkboxHomeValue="ngModel"
                           (change)="onHomeAddressCheckbox($event)">
                    <div class="checkbox-parent">
                      <div class="checkbox-background"></div>
                      <i class="fas fa-check"></i>
                    </div>
                  </div>
                  <div class="policy-text">
                    <label for="addressListHome" class="label">
                      Ship to this address listed
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group col-sm-8">
                <input type="text" class="form-control address-form" id="exampleInputAddressLine1" name="AddressLine1"
                       placeholder="Address Line 1" [(ngModel)]="addressLine1" readonly>
                <input type="text" class="form-control address-form" id="exampleInputAddressLine2" name="AddressLine2"
                       placeholder="Address Line 2" [(ngModel)]="addressLine2" readonly>
                <div class="form-row">
                  <div class="col-md-5">
                    <input type="text" class="form-control address-form" id="exampleInputCity" name="City"
                           placeholder="City" [(ngModel)]="city" readonly>
                  </div>
                  <div class="col-md-2" style="margin: 0 5px;">
                    <input type="text" class="form-control address-form" id="exampleInputState" name="State"
                           placeholder="State" [(ngModel)]="state" readonly>
                  </div>
                  <div class="col-sm-3">
                    <input type="text" class="form-control address-form" id="exampleInputZIP" name="ZIP"
                           placeholder="ZIP" [(ngModel)]="zip" readonly>
                  </div>
                </div>
              </div>
            </div>
            <!--<div class="form-row" *ngIf="!isHomeAddressConf">
              <div style="font-family:'UHCSans', Helvetica, Arial, sans-serif;">
                Note: If a member has a Confidential address. Ship to this address listed information will not populated.
              </div>
            </div>-->
            <div class="form-row">
              <div class="form-group col-sm-4">
                <div class="d-flex">
                  <div class="checkbox-container">
                    <input type="checkbox" id="addressListAlt" name="addressListAlt" [(ngModel)]="checkboxAlt" #checkboxAltValue="ngModel"
                           (change)="onAltAddressCheckbox($event)" [required]="!isHomeAddressConf">
                    <div class="checkbox-parent">
                      <div class="checkbox-background"></div>
                      <i class="fas fa-check"></i>
                    </div>
                  </div>
                  <div class="policy-text">
                    <label for="addressListAlt" class="label">
                      Ship to this address
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group col-sm-8">
                <div style="height:60px">
                  <input type="text" class="form-control address-form-alt" id="exampleInputAltAddressLine1"
                         name="AltAddressLine1" placeholder="Street Address" [(ngModel)]="altAddressLine1" #altAddressLine1Value="ngModel" [required]="checkboxAlt">
                  <div *ngIf="altAddressLine1Value.invalid && (altAddressLine1Value.dirty || altAddressLine1Value.touched)" class="app-alert">
                    <div *ngIf="altAddressLine1Value.errors.required">
                      Street Address is required.
                    </div>
                  </div>
                </div>
                <input type="text" class="form-control address-form-alt" id="exampleInputAltAddressLine2"
                       name="AltAddressLine2" placeholder="Apt # / Unit / Suite #" [(ngModel)]="altAddressLine2">
                <div class="form-row">
                  <div class="col-md-5">
                    <input type="text" class="form-control address-form-alt" id="exampleInputAltCity" name="AltCity"
                           placeholder="City" [(ngModel)]="altCity" #altCityValue="ngModel" [required]="checkboxAlt">
                    <div *ngIf="altCityValue.invalid && (altCityValue.dirty || altCityValue.touched)" class="app-alert">
                      <div *ngIf="altCityValue.errors.required">
                        City is required.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2" style="margin: 0 5px;">
                    <!--<input type="text" class="form-control address-form-alt" id="exampleInputAltState" name="AltState"
                    placeholder="State" [(ngModel)]="altState">-->
                    <button class="btn dropdown-toggle address-form-alt"
                            type="button"
                            id="dropdownSate"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false" style="width: 170px; height: 38px;">
                      {{altState}}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownSate" style="cursor:pointer;height: 200px;overflow-y: auto;">
                      <a *ngFor="let elem of dropdownSate"
                         class="dropdown-item"
                         (click)="onDropdownChangeLob(elem.id)">{{elem.id}}</a>
                    </div>
                    <div *ngIf="altState==='State'&& checkboxAlt" class="app-alert">
                      State is required.
                    </div>
                  </div>
                  <div class="col-sm-3" style="height: 70px;">
                    <input type="text" class="form-control address-form-alt" id="exampleInputAltZIP" name="AltZIP"
                           placeholder="ZIP" [(ngModel)]="altZip" pattern="[0-9]{5}" #altZipValue="ngModel"
                           (keydown)="onKeyDownZipCode($event)" [required]="checkboxAlt">
                    <span class="hint-text" style="padding-left: 10px;">#####</span>
                    <div *ngIf="altZipValue.invalid && (altZipValue.dirty || altZipValue.touched)" class="app-alert">
                      <div *ngIf="altZipValue.errors.required">
                        ZIP is required.
                      </div>
                      <div *ngIf="altZipValue.errors?.pattern">
                        ZIP is invalid.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="font-family: 'UHCSans', Helvetica, Arial, sans-serif; color: #003C71;    padding: 10px 0;font-weight: 600;">
              <p style="margin-bottom: 0.1rem">
                <span>
                  Signing up for this program online requires that we send text
                  messages to your mobile phone. Please provide your mobile (cell) phone
                  number below.
                </span>
              </p>
            </div>

            <div class="form-row" style="padding-left: 40px; ">
              <div class="form-group col-sm-8">
                <div class="form-row" style="height: 120px;">
                  <div class="col-md-4">
                    <input type="text" class="form-control address-form-alt" id="exampleInputMobile" name="Mobile"
                           placeholder="Mobile Phone #" [(ngModel)]="mobile" #mobileValue="ngModel" oncopy="return false"
                           onpaste="return false" required pattern="[1-9]{1}[0-9]{2}-[0-9]{3}-[0-9]{4}"
                           (keydown)="onKeyDownMobileNumber($event)">
                    <span class="hint-text">###-###-####</span>
                    <div *ngIf="mobileValue.invalid && (mobileValue.dirty || mobileValue.touched)" class="app-alert">
                      <div *ngIf="mobileValue.errors.required">
                        Mobile Phone# is required.
                      </div>
                      <div *ngIf="mobileValue.errors?.pattern">
                        Invalid Mobile Phone#.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4" [hidden]="mobileValue.invalid">
                    <input type="text" class="form-control address-form-alt" id="exampleInputConfirmMobile"
                           name="ConfirmMobile" placeholder="Re-enter Mobile Phone #" [(ngModel)]="mobileConfirmed"
                           oncopy="return false" onpaste="return false" #mobileConfirmedValue="ngModel" required
                           (keydown)="onKeyDownConfirmMobileNumber($event)" (keyup)="onKeyUpConfirmMobileNumber($event)">
                    <span class="hint-text">###-###-####</span>
                    <div *ngIf="mobileConfirmedValue.invalid && (mobileConfirmedValue.dirty || mobileConfirmedValue.touched)"
                         class="app-alert">
                      <div *ngIf="mobileConfirmedValue.errors.required">
                        Confirm Mobile Phone# is required.
                      </div>
                    </div>
                    <div *ngIf="!mobileConfirmedValue.invalid && isMobileConfirmed" class="app-alert">
                      Mobile Phone# must match.
                    </div>
                  </div>
                  <div class="app-alert" style="width:120%;font-family:'UHCSans', Helvetica, Arial, sans-serif;" [hidden]="mobilePhoneCheck || isMobilePhoneCheck">
                    Please check this box to receive program text messages, including the link to download the care management program mobile app.
                  </div>
                </div>
                <div class="d-flex" style="width: 150%;">
                  <div class="checkbox-container">
                    <input type="checkbox" id="mobilePhoneCheck" name="mobilePhoneCheck" [(ngModel)]="mobilePhoneCheck"
                           #checkboxMobileValue="ngModel" (change)="onMobilePhoneCheckbox($event)" required>
                    <div class="checkbox-parent">
                      <div class="checkbox-background"></div>
                      <i class="fas fa-check"></i>
                    </div>
                  </div>
                  <div class="policy-text">
                    <label for="mobilePhoneCheck" class="label">
                      By clicking this checkbox, you agree that we can send text messages to you to set up your care
                      management program app account,
                      including a message containing your link to access the program app. During the program, we’ll also
                      send you text messages relating
                      to your participation in this program, including to remind you to check in on the app to track
                      your health readings and to help you
                      maintain your account. By agreeing, you acknowledge that text messages are unencrypted. As is the
                      case with any text you receive,
                      there is a possibility that information sent in a text message can be read by other parties
                      besides the person to whom it is addressed.
                      You may stop text messages at any time. Your carrier’s message and data rates apply to any text
                      messages. For our Texting Terms &
                      Conditions:
                      <a href="https://www.uhc.com/txt-tcs" target="_blank"
                         style="font-weight: 600;color: #003C71;">Click Here</a>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div style="font-family: 'UHCSans', Helvetica, Arial, sans-serif; color: #003C71;    padding: 10px 0;font-weight: 600;">
              <p style="margin-bottom: 0.1rem">
                <span>
                  We would like to send you periodic emails. Please provide your
                  email address below.
                </span>
              </p>
            </div>

            <div class="form-row" style="padding-left: 40px;">
              <div class="form-group col-sm-8">
                <div class="form-row" style="height: 80px;">
                  <div class="col-md-4">
                    <input type="text" class="form-control address-form-alt" id="exampleInputEmail" name="Email"
                           placeholder="Email Address: name@domain.com" [(ngModel)]="email" #emailValue="ngModel"
                           pattern="[a-zA-Z]+[!#$%&'*+-/=?^_`{|]*[0-9a-zA-Z]+[@][a-zA-Z]+[0-9a-zA-Z]+[.][a-zA-Z]+[0-9a-zA-Z]*[.]*[a-zA-Z]+"
                           oncopy="return false" onpaste="return false"
                           (keydown)="onKeyDownEmailValue($event)">
                    <div *ngIf="emailValue.invalid && (emailValue.dirty || emailValue.touched)" class="app-alert">
                      <div *ngIf="emailValue.errors.required">
                        Email Address is required.
                      </div>
                      <div *ngIf="emailValue.errors?.pattern">
                        Invalid Email Address.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4" [hidden]="emailValue.invalid || isConfirmEmailValueHide">
                    <input type="text" class="form-control address-form-alt" id="exampleInputConfirmEmail"
                           name="ConfirmEmail" placeholder="Re-enter Email Address" [(ngModel)]="emailConfirmed"
                           #emailConfirmedValue="ngModel" (keyup)="onKeyUpConfirmEmail($event)"
                           oncopy="return false" onpaste="return false"
                           [required]="!(emailValue.invalid || isConfirmEmailValueHide)">
                    <div *ngIf="emailConfirmedValue.invalid && (emailConfirmedValue.dirty || emailConfirmedValue.touched)" class="app-alert">
                      <div *ngIf="emailConfirmedValue.errors.required">
                        Re-enter Email Address is required.
                      </div>
                    </div>
                    <div *ngIf="!emailConfirmedValue.invalid && isEmailConfirmed" class="app-alert">
                      Email Address must match.
                    </div>
                  </div>
                </div>
                <div class="d-flex" style="width: 150%;">
                  <div class="checkbox-container">
                    <input type="checkbox" id="emailCheck" name="emailCheck" ngModel #checkboxEmailValue="ngModel"
                           (change)="onEmailCheckbox($event)">
                    <div class="checkbox-parent">
                      <div class="checkbox-background"></div>
                      <i class="fas fa-check"></i>
                    </div>
                  </div>
                  <div class="policy-text">
                    <label for="emailCheck" class="label">
                      By clicking this checkbox, you agree we can send you emails with specific care information, such
                      as provider names or programs
                      that may identify medical and/or mental health conditions (e.g., diabetes, health disease,
                      pregnancy, etc.). These emails may be
                      about programs you are eligible for, are already enrolled in, or care reminders. These emails
                      containing protected health information
                      will be sent via unencrypted methods. By choosing this preference you acknowledge and accept that
                      communications may be sent
                      unencrypted and there is some risk of disclosure or interception of the contents of these
                      communications.
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div style="right: 0;position: absolute; padding-bottom: 20px;">
              <button type="submit" class="btn btn-lg btn-primary next-button"
                      [disabled]="!submitForm.form.valid || (!checkboxHome&&!checkboxAlt) || (altState==='State'&& checkboxAlt) || (!mobileConfirmedValue.invalid && isMobileConfirmed) || (!emailConfirmedValue.invalid && isEmailConfirmed)">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div *ngIf="!isEnrollmentSuccess">
    <div>
      <h4 class="error-header" id="responsetext" style="font-family:'UHC Sans';font-weight:500;color:#003C71;padding: 20px 15% 0 4rem;text-align:center">
        {{eligibilityRespMsg}}
      </h4>
      <!--<a href="javascript:void(0)" id="retryForm" (click)="onResubmit()" [hidden]="reenroll">Click here to try again</a>-->
    </div>
  </div>
</div>

<ngx-spinner bdOpacity=0.9
             bdColor="rgba(170,170,170,0.7)"
             size="medium"
             color="#003C71"
             type="ball-atom">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

