import { Injectable } from '@angular/core';
import { MemberResponseModel } from '../MemberResponseModel';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MemberEnrollmentService {

  constructor(private httpClient: HttpClient) { }
  createMemberProfile(
      firstName: string
    , lastName: string
    , dob: string
    , memberId: string
    , addressLine1: string
    , addressLine2: string
    , city: string
    , zipCode: string
    , state: string
    , mobileNbr: string
    , email: string
    , indvId: string
    , populationGroup: string
    , auditId: string) {
    let dobformat = dob.split('/');
    return this.httpClient.post<MemberResponseModel>('/nc-member-create',
      {
        "firstName": firstName,
        "lastName": lastName,
        "dob": dobformat[2] + '-' + dobformat[0] + '-' + dobformat[1],
        "memberId": memberId,
        "addressLineOne": addressLine1,
        "addressLineTwo": addressLine2,
        "city": city,
        "zip": zipCode,
        "state": state,
        "mobileNbr": mobileNbr,
        "email": email,
        "indvId": indvId,
        "populationGroup": populationGroup,
        "auditId": auditId
      });
  }
}
